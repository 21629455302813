import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Button from '../components/button/button'
import Testimonial from '../components/testimonial/testimonial'
import Services from '../components/services/services'
import SEO from "../components/seo"
import { motion } from "framer-motion"

import image from '../images/back.jpg'

const IndexPage = () => {
  const transition = {
    type: "spring",
    mass: 0.35,
    stiffness: 75,
    duration: 0.3
  };

  return (
  <Layout>
    <SEO title="Home" />
    <section id={`cover`} className={`primary lg center-group title`} 
      style={{height: '100vh',
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        boxSizing: `border-box`
      }}>
      <div className={`content--md`}>
        <motion.h3 className={`center thin`} 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={transition}>A creative voyage towards your vision</motion.h3>
        <motion.h1 className={`center`} initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={transition}>We build meaningful digital solutions to help businesses grow</motion.h1>
        <motion.div className={`center-group`} initial={{ opacity: 0, y: -60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 60 }}
          transition={transition}><Link to="/connect"><Button variant={`filled`}>Work with us</Button></Link></motion.div>
      </div>
    </section>
    <section className={`dark`}>
      <div className={`content--sm`}>
        <h2 className={`center`}>Creavo helps you create elegant brand experiences with your customers.</h2>
        <p className={`center lg`}>We craft digital products that combine technology, design, and business strategy to provide seamless and memorable interactions.</p>
        <div className={`center-group`}><Button>Our Process</Button></div>
      </div>
    </section>
    <section>
      <div className={`content--md`}>
        <div className={`content--md`}>
          <h5 className={`center text med`}>Our Services</h5>
          <h2 className={`center`}>Digital growth can be quick and easy. Let us show you how?</h2>
        </div>
      </div>
      <div className={`content`}>
        <Services />
        <div className={`center-group`}><Link to="/services"><Button variant={`filled`}>Learn more</Button></Link></div>
      </div>
    </section>
    <section className={`dark remove-padding`}>
      <div className={`split-wrapper`}>
        <div className={`split-40 content-padding`}>
            <h3>What do our clients say?</h3>
            <p>We like working with our clients to understand their goals and find solutions.</p>
        </div>
        <div className={`split-60 content-padding dark`}>
          <Testimonial />
        </div>
      </div>
    </section>
  </Layout>
)}

export default IndexPage
